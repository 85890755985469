import { Avatar, Promo, UserInfo, TableSmall, TableLarge } from ".";
import { ReferalsBarChart } from "./ReferalsBarChart";
import { useGetRefferalsQuery} from "../../toolkitReducers/cryptouch";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";

const ReferalsPage = () => {
  const { data } = useGetRefferalsQuery();
const theme = useSelector(selectTheme)
  return (
    <div
      className={
        theme === "light"
          ? "profile-page__body-wrapper referals-page"
          : "profile-page__body-wrapper referals-page dark-profile"
      }
    >
      <div className="profile-page__sections-wrapper medium-wrapper">
        <section className="referals-page-section">
          <div className="referals-section-user-panel">
            <Avatar />
            <UserInfo balance={data && data.bonus_crypto_balance} />
          </div>
          <Promo lvl={data && data.current_referral_level} />
        </section>
        <section className="referals-page-section">
          <TableSmall refferals={data} />
          <div className="referals-common-profit-wrapper">
            <ReferalsBarChart referals={data} />
          </div>
        </section>
        <section className="referals-page-section">
          <TableLarge refferals={data} />
        </section>
      </div>
    </div>
  );
};

export { ReferalsPage };
