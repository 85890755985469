import ProfilePicture from "../../assets/images/profile.svg";
import ProfilePictureDark from "../../assets/images/profile-dark.svg";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
const Avatar = () => {
  const theme = useSelector(selectTheme);
  return (
    <div className="referals-section-photo-wrapper">
      <img
        src={theme === "light" ? ProfilePicture : ProfilePictureDark}
        alt="profile"
      />
    </div>
  );
};

export {
    Avatar,
}