import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./toolkitReducers";
import { cryptouchAPI } from "./toolkitReducers/cryptouch";
import { ipDataApi } from "./toolkitReducers/ipDataApi";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), cryptouchAPI.middleware, ipDataApi.middleware]
}


)

setupListeners(store.dispatch)