import { useTranslation } from "react-i18next";
import { transformDateTimeForSessionsTable } from "../../helpers/createDateString";
const TableSmall = ({ refferals }) => {
  const { t } = useTranslation();

  return (
    <div className="referals-page__table-wrapper">
      <table>
        <thead>
          <tr className="referals-page__thead rel">
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Дата деп")}</span>
              </div>
            </th>
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Почта реферала")}</span>
              </div>
            </th>
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Сумма деп")} USDT</span>
              </div>
            </th>
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Сумма деп")} BTC</span>
              </div>
            </th>
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Сумма деп")} ETH</span>
              </div>
            </th>
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Бонус")} USDT</span>
              </div>
            </th>
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Бонус")} BTC</span>
              </div>
            </th>
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Бонус")} ETH</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {refferals?.all_referrals?.length > 0 &&
            refferals.all_referrals.map((ref, idx) => (
              <tr
                className="referals-page__table-body-row"
                key={`${idx}-small-table-row`}
              >
                <td>
                  {transformDateTimeForSessionsTable(ref.last_deposit_date) ||
                    "-"}
                </td>
                <td>{ref.email}</td>
                <td>{ref.last_deposit_sum_usdt || 0}</td>
                <td>{ref.last_deposit_sum_btc || 0}</td>
                <td>{ref.last_deposit_sum_eth || 0}</td>
                <td>{ref.bonus_crypto_balance.usdt || 0}</td>
                <td>{ref.bonus_crypto_balance.btc || 0}</td>
                <td>{ref.bonus_crypto_balance.eth || 0}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export { TableSmall };
