export const PlanSwitcher = ({ handleSelectPlan, plan }) => {
  return (
    <div className="plan-switcher">
      <button
        onClick={() => handleSelectPlan("base")}
        className={plan === "base" ? "selected" : ""}
      >
        Base
      </button>
      <button
        onClick={() => handleSelectPlan("pro")}
        className={plan === "pro" ? "selected" : ""}
      >
        Pro
      </button>
    </div>
  );
};
