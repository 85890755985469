// use this function to check whether user funds is sufficient to provide withdraw operation

function checkUserFunds(currency, amount) {
  let sufficient = false;
  switch (currency) {
    case "usdt":
      sufficient = amount >= 10;
      break;
    case "btc":
      sufficient = amount >= 0.00016;
      break;
    case "eth":
      sufficient = amount >= 0.0029;
      break;
    default:
      console.log("unsupported currency type");
  }

  return sufficient;
}
// use this function to check whether user funds is sufficient to launch respective bot (algorythm)
function checkFundsToLaunchBot(prom, userData) {
  if (!userData) {
    return;
  }
  if (prom === 10) {
    return (
      Number(userData.trading_crypto_balance_btc) >= 0.00014 ||
      Number(userData.trading_crypto_balance_eth) >= 0.0026 ||
      Number(userData.trading_crypto_balance_usdt) >= 10
    );
  }
  if (prom === 100) {
    return (
      Number(userData.trading_crypto_balance_btc) >= 0.0014 ||
      Number(userData.trading_crypto_balance_eth) >= 0.026 ||
      Number(userData.trading_crypto_balance_usdt) >= 100
    );
  }
}

function checkAmountToLaunchBot(amount, botType, selectedCoin) {
  if (botType === "base") {
    let sufficientAmount;
    switch (selectedCoin) {
      case "btc":
        sufficientAmount = 0.00014;
        break;
      case "eth":
        sufficientAmount = 0.0026;
        break;
      case "usdt":
        sufficientAmount = 10;
        break;
      default:
        console.log("unsupported coin");
        break;
    }
    return Number(amount) >= sufficientAmount;
  }
  if (botType === "pro") {
    let sufficientAmount;
    switch (selectedCoin) {
      case "btc":
        sufficientAmount = 0.0014;
        break;
      case "eth":
        sufficientAmount = 0.026;
        break;
      case "usdt":
        sufficientAmount = 100;
        break;
      default:
        console.log("unsupported coin");
        break;
    }
    return Number(amount) >= sufficientAmount;
  }
}
export { checkUserFunds, checkFundsToLaunchBot, checkAmountToLaunchBot };
