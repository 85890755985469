import { useState, useEffect, useRef } from "react";
import i18n from "../i18n";
import { useSelector, useDispatch } from "react-redux";
import { selectTheme } from "../toolkitReducers/selectors";
import { setLanguage } from "../toolkitReducers/languageSlice";
import { selectLanguage } from "../toolkitReducers/selectors";
const LanguageSelect = () => {
  const theme = useSelector(selectTheme);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);

  const dropdownWindowRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      dropdownWindowRef.current &&
      !dropdownWindowRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const handleLanguageChange = (language) => {
    // setCurrentLanguage(language);


    i18n.changeLanguage(language);
    dispatch(setLanguage(language));
    localStorage.setItem("lang", language);
    setIsOpen(false);
  };

  const handleLanguageClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="custom-select-wrapper">
      <div className="custom-select-pages">
        <button
          tabIndex="0"
          aria-expanded="false"
          aria-controls="dropdown-1"
          onClick={handleLanguageClick}
        >
          <span
            data-button-label
            style={{ color: theme === "light" ? "black" : "white" }}
          >
            {language.toUpperCase() || "RU"}
          </span>

          <svg
            width="12"
            height="6"
            viewBox="0 0 12 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6 6L11.1962 0.75H0.803848L6 6Z" fill="#4D4D4D" />
          </svg>
        </button>

        {isOpen && (
          <ul data-dropdown id="dropdown-2" ref={dropdownWindowRef}>
            <li>
              <button
                data-dropdown-button
                className="btn--reset link custom-select__dropdown-button"
                onClick={() => handleLanguageChange("ru")}
              >
                RU
              </button>
            </li>

            <li>
              <button
                data-dropdown-button
                className="btn--reset link custom-select__dropdown-button"
                onClick={() => handleLanguageChange("en")}
              >
                EN
              </button>
            </li>
          </ul>
        )}

        <input
          type="hidden"
          className="custom-select__input-hidden"
          value="10"
        />
      </div>
    </div>
  );
};
export { LanguageSelect };
