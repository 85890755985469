import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
const CustomSelect = ({
  text = "Отобразить",
  handleDisplayPerPage,
  perPage,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const theme = useSelector(selectTheme);
  const handleSelect = (value) => {
    setIsOpen(false);
    handleDisplayPerPage(value);
  };


  return (
    <div
      className={
        theme === "light" ? "custom-select-wrapper" : "custom-select-wrapper dark-profile"
      }
    >
      <span className="custom-select-label">{t(text)}</span>
      <div className="custom-select-pages">
        <button
          tabIndex="0"
          aria-expanded="false"
          aria-controls="dropdown-2"
          onClick={() => toggle()}
        >
          <span data-button-label>{perPage || t("Все")}</span>

          <svg
            width="12"
            height="6"
            viewBox="0 0 12 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6 6L11.1962 0.75H0.803848L6 6Z" fill="#4D4D4D" />
          </svg>
        </button>

        {isOpen && (
          <ul data-dropdown id="dropdown-2">
            <li data-option-value="1" aria-selected="false" key={124 + 1}>
              <button
                data-dropdown-button
                className="btn--reset link custom-select__dropdown-button"
                onClick={() => handleSelect(1)}
              >
                1
              </button>
            </li>

            <li data-option-value="2" aria-selected="false" key={124 + 2}>
              <button
                data-dropdown-button
                className="btn--reset link custom-select__dropdown-button"
                onClick={() => handleSelect(2)}
              >
                2
              </button>
            </li>

            <li data-option-value="3" aria-selected="false" key={124 + 3}>
              <button
                data-dropdown-button
                className="btn--reset link custom-select__dropdown-button"
                onClick={() => handleSelect(3)}
              >
                3
              </button>
            </li>
            <li data-option-value="5" aria-selected="true" key={124 + 4}>
              <button
                data-dropdown-button
                className="btn--reset link custom-select__dropdown-button"
                onClick={() => handleSelect(5)}
              >
                5
              </button>
            </li>
            <li data-option-value="10" aria-selected="true" key={124 + 5}>
              <button
                data-dropdown-button
                className="btn--reset link custom-select__dropdown-button"
                onClick={() => handleSelect(10)}
              >
                10
              </button>
            </li>
            <li data-option-value="30" aria-selected="true" key={124 + 6}>
              <button
                data-dropdown-button
                className="btn--reset link custom-select__dropdown-button"
                onClick={() => handleSelect(30)}
              >
                30
              </button>
            </li>
            <li data-option-value="30" aria-selected="true" key={124 + 7}>
              <button
                data-dropdown-button
                className="btn--reset link custom-select__dropdown-button"
                onClick={() => handleSelect(null)}
              >
                {t("Все")}
              </button>
            </li>
          </ul>
        )}

        <input
          type="hidden"
          className="custom-select__input-hidden"
          value="10"
        />
      </div>
    </div>
  );
};
export { CustomSelect };
