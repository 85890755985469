import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, Link } from 'react-router-dom'
import { userLogout } from './../toolkitReducers'
import { useTranslation } from 'react-i18next';
import { createDateString } from '../helpers/createDateString';
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import { ReactComponent as LogoIcon } from './../assets/images/logo.svg';
import { ReactComponent as LogoIconDark } from "../assets/icons/logo-header-main.svg";
import { ReactComponent as IconProfile } from '../assets/icons/ProfileIcon.svg'
import { ReactComponent as LoginIcon } from '../assets/icons/LoginIcon.svg'
import { ReactComponent as LogoutIcon } from '../assets/icons/LogoutIcon.svg'
import { ReactComponent as BellIcon } from '../assets/icons/Bell.svg'
import { toggleTheme } from '../toolkitReducers/themeSlice';
import { selectTheme } from "../toolkitReducers/selectors";
export default function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useSelector(selectTheme)
  const { t } = useTranslation();
  const logout = () => {
    dispatch(userLogout())
    navigate('/login')
  }
  const handleToggleTheme = () => {
    dispatch(toggleTheme());
  };
  return (
    <header
      className={theme === "light" ? "main-header" : "main-header dark-profile"}
    >
      <div className="main-header__wrapper rel">
        <div className="main-header-info-logo">
          <Link to="/profile">
            {theme === "light" ? (
              <LogoIcon className="img-abs" />
            ) : (
              <LogoIconDark />
            )}
          </Link>
        </div>
        <div className="main-header__content">
          <span className="main-header__content-title">
            {t("Balance update")}
          </span>
          <span className="main-header__content-date">
            {createDateString()}
          </span>
        </div>
        <div className="main-header__icons">
          <button onClick={handleToggleTheme} className="switch-mode">
            {theme === "light" ? <MdOutlineDarkMode /> : <MdOutlineLightMode />}
          </button>
          <div className="main-header__icons-profile rel">
            <NavLink
              to="profile"
              className="main-header__icons-profile main-header-icon btn"
            >
              <BellIcon />
            </NavLink>
          </div>
          <div className="main-header__icons-profile rel">
            <NavLink
              to="profile"
              className="main-header__icons-profile main-header-icon btn"
            >
              <IconProfile />
            </NavLink>

            <div className="main-header__icons-profile-login">
              <NavLink
                to="profile"
                className="main-header__icons-profile-login-row main-header-icon link"
              >
                <div className="main-header__icons-profile-login-icon">
                  <LoginIcon />
                </div>
                <p className="main-header__icons-profile-login-label">
                  {t("Профиль")}
                </p>
              </NavLink>
              <div
                onClick={logout}
                className="main-header__icons-profile-login-row main-header-icon"
              >
                <div className="main-header__icons-profile-login-icon">
                  <LogoutIcon />
                </div>
                <p className="main-header__icons-profile-login-label">
                  {t("Выход")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
