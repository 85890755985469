import Birthday from "../../assets/images/events-section/cryptouch-birthday.png";
import Moscow from "../../assets/images/events-section/cryptouch-moscow.png";
import Spain from "../../assets/images/events-section/spain.png";
import { useTranslation } from "react-i18next";
import { ReactComponent as VideoPlay } from "../../assets/icons/promo-section/video-play.svg";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../toolkitReducers/selectors";
export const Events = () => {
  const { t } = useTranslation();
  const lang = useSelector(selectLanguage);
  return (
    <section className="main-page-section events-section">
      <div className="section-inner-container">
        <h3>{t("CrypTouch события")}</h3>
        <div className="images-row">
          <div className="thumb">
            <img src={Birthday} alt={t("День рождения Cryptouch")} />
            <a
              rel="noreferrer"
              target="_blank"
              href={
                lang === "en"
                  ? "https://www.youtube.com/watch?v=h_TFYDUV9sM"
                  : "https://www.youtube.com/watch?v=CZHvXt1r9aM"
              }
            >
              <VideoPlay />
            </a>
          </div>
          <div className="thumb">
            <img src={Moscow} alt={t("Москва")} />
            <a
              rel="noreferrer"
              target="_blank"
              href={
                lang === "en"
                  ? "https://www.youtube.com/watch?v=rV2uSJMm2Hs"
                  : "https://www.youtube.com/watch?v=rV2uSJMm2Hs"
              }
            >
              <VideoPlay />
            </a>
          </div>
          <div className="thumb">
            <img src={Spain} alt={t("Испания")} />
            <p>{t("Скоро") + " 13.01"}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
