import React from "react";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useGetGlobalProfitQuery } from "../../toolkitReducers/cryptouch";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



export default function G7() {
  const theme = useSelector(selectTheme);
  const {t} = useTranslation()
const options = {
  plugins: {
    legend: {
      display: false,
      labels: {
        color: theme === "light" ? "black" : "white",
      },
    },
  },
  scales: {
    y: {
      grid: {
        color: theme === "light" ? "lightgray" : "rgba(255, 255, 255, 0.5)",
      },
      ticks: {
        color: theme === "light" ? "black" : "white",
        // stepSize: 1,
        beginAtZero: true,
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: theme === "light" ? "black" : "white",
        // stepSize: 100,
        // beginAtZero: true,
      },
    },
  },
};
  const { data: globalProfit } = useGetGlobalProfitQuery();

  let labelsFromData = globalProfit?.daily_profit_data?.map(
    (item) => item.day_name
  );
  let dataGraphic = globalProfit?.daily_profit_data?.map(
    (item) => item.daily_profit
  );


  const data = {
    // labels: [1,2,3,4,5,6,7],
    labels: labelsFromData,
    datasets: [
      {
        label: t("Общая прибыль платформы за сутки"),
        data: dataGraphic,
        borderColor: " #9f38d2",
      },
    ],
  };

  return <>{dataGraphic && <Line options={options} data={data} />}</>;
}
