import { ReactComponent as Cryptouch } from "../../assets/icons/feedback-section/cryptouch.svg";
import { ReactComponent as Star } from "../../assets/icons/feedback-section/star.svg";
import { useTranslation } from "react-i18next";

const FeedbackCard = ({text, rating}) => {

  const { t } = useTranslation();
    return (
                    <div className="feedback-thumb">
              <p className="description">
                {t(
                 text
                )}
              </p>
              <div className="lower-part-holder">
                <div className="icon-thumb">
                  <Cryptouch />
                </div>
                <div>
                  <p className="rating-category">{t("Customers")}</p>
                  <ul className="star-list">
                    <li>
                      <Star />
                    </li>
                    <li>
                      <Star />
                    </li>
                    <li>
                      <Star />
                    </li>
                    <li>
                      <Star />
                    </li>
                    <li className={rating < 5 ? "gray" : "violet"}>
                      <Star />
                    </li>
                  </ul>
                </div>
                <p className="description">{t("SourceForge")}</p>
              </div>
            </div>
    )
}

export {
    FeedbackCard
}