import { useState } from "react";
import { TransactionsFilter } from "./TransactionsFilter";
import { useGetRisksQuery } from "../../toolkitReducers/cryptouch";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "../Binary/CustomSelect";
import { ReactComponent as Completed } from "../../assets/icons/completed.svg";

export default function Table({ plan }) {
  const { data: risks } = useGetRisksQuery();
  const { t } = useTranslation();
  const [selectedCurrecy, setSelectedCurrency] = useState("btc");
  const [perPage, setPerPage] = useState(5);
  const [expanded, setExpanded] = useState(false);
  const handleDisplayPerPage = (count = null) => {
    setPerPage(count);
  };
  const toggleDropdown = () => setExpanded(!expanded);
  function handleSelectCurrecy(currencyName) {
    setSelectedCurrency(currencyName);
    setExpanded(false);
  }

  return (
    <>
      <CustomSelect
        handleDisplayPerPage={handleDisplayPerPage}
        perPage={perPage}
      />
      <table className="algorithms-history__table-body">
        <thead>
          <tr className="algorithms-history__table-heading-row rel">
            <th>{t("Дата")}</th>
            <th className="currency-th">
              {t("Валюта")}
              <TransactionsFilter
                {...{ handleSelectCurrecy, expanded, toggleDropdown }}
              />
            </th>
            <th>{t("Сумма прибыли")}</th>
            <th>{t("% прибыли")}</th>

            <th>
              <div className="algorithms-history__table-td-wrapper">
                <span>{t("Статус")}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {risks &&
            risks?.length > 0 &&
            [...risks]
              .reverse()
              .splice(0, perPage || risks.length - 1)
              .map((risk, index) => (
                <tr
                  key={index + "enrollment-table-row"}
                  className="ennrolment-table"
                >
                  <td>{risk.date}</td>
                  <td className="amount-cell">
                    {selectedCurrecy.toUpperCase()}
                  </td>
                  <td>{risk[`${plan}_profit_${selectedCurrecy}`]}</td>
                  <td>{risk[`${plan}_risk_${selectedCurrecy}`]}</td>
                  <td className="table-data-with-icon">
                    <span>
                      {/* {session.status === "success" ? <Completed /> : <Failed />} */}
                      <Completed />
                    </span>
                    {/* <span>{t(session.status)}</span> */}
                    <span className="hidden">{t("Success")}</span>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </>
  );
}
