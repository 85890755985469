import { useState } from "react";
import Table from "./Table";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectTheme } from "../../toolkitReducers/selectors";
import { PlanSwitcher } from "./PlanSwitcher";
import { BotsSection } from "../../Elements/BinaryBots/BotsSection";
export default function Algoritms() {
  const [plan, setPlan] = useState("base");

  const { t } = useTranslation();

  const theme = useSelector(selectTheme);

  const handleSelectPlan = (plan) => setPlan(plan);

  return (
    <div className="algorithms-page algorithms-page__body-wrapper page__body-wrapper">
      <div className="algorithms-page__sections-wrapper page__sections-wrapper medium-wrapper">
        <BotsSection />
        <section
          className={
            theme === "light"
              ? "algorithms-history"
              : "algorithms-history dark-profile"
          }
        >
          <div className="tabs__accordions-wrapper">
            <div
              className="tabs__list"
            >
              <div className="table-title-wrapper">
                <h2>{t("Профит")}</h2>
                <PlanSwitcher handleSelectPlan={handleSelectPlan} plan={plan} />
              </div>
              <div className="algorithms-history__table-wrapper">
                <Table plan={plan} />
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
      </div>
    </div>
  );
}
