import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../helpers";

export const ipDataApi = createApi({
  reducerPath: "ipDataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.ipdata.co`,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Token  ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchUserIp: builder.query({
      query: () => ({
        url: `?api-key=${process.env.REACT_APP_JSON_API_KEY}`
      }),
    }),
  }),
});

export const { useFetchUserIpQuery} = ipDataApi;