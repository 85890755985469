import { AuthLogo } from "./AuthLogo";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo-large.svg";
import { LanguageSelect } from "../../Elements/LanguageSelect";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getToken } from "../../helpers";
import { getUserData } from "../../toolkitReducers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export const TemporaryPage = (params) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isAuth);
  console.log(isLoggedIn);
  useEffect(() => {
    if (getToken() !== null) {
      dispatch(getUserData());
    }

    isLoggedIn && navigate("/profile");
  }, [dispatch, isLoggedIn, navigate]);
  return (
    <>
      <header className="temporary-page-header">
        <div className="main-header-info-logo">
          <Link to="/profile">
            <LogoIcon className="img-abs" />
          </Link>
        </div>
        <div className="page-header-right-side">
          <LanguageSelect />
          <Link className="btn" to="/login">
            {t("Войти")}
          </Link>
        </div>
      </header>
      <div className="temporary-page page">
        <AuthLogo />

        <section className="pass-section small-wrapper">
          <div className="pass-section__headings-wrapper auth-headings">
            <h1 className="pass-section__heading h3">
              {t(
                "На данный момент сайт находится на стадии активной разработки"
              )}
            </h1>
          </div>

          <div className="form__main-content">
            <div className="pass-section__side">
              <Link className="btn" to="/register">
                {t("Регистрация")}
              </Link>
              <Link className="btn" to="/login">
                {t("Войти")}
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
