
import CustomSelect from "./Library/CustomSelect";
import { Dropdown } from "./Dropdown";
import { useSelector } from "react-redux";
import { selectTheme } from "../toolkitReducers/selectors";
export default function Aside() {

const theme = useSelector(selectTheme)
  return (
    <aside className={theme === 'light' ? "main-nav" : "main-nav dark-profile"}>
      <Dropdown />
      <CustomSelect />
    </aside>
  );
}
