import { createSlice } from "@reduxjs/toolkit";
import i18n from "../i18n";
import { storedLanguage } from "../i18n";
const initialState = {
  language: storedLanguage, // Default language
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage(state, action) {
      localStorage.clear()
      localStorage.setItem('lang', action.payload)
      state.language = action.payload;
      i18n.changeLanguage(action.payload);
      
      
    },
  },
});
export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
