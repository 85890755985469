import en from "./translate/en.json";
import ru from "./translate/ru.json";

import { initReactI18next } from "react-i18next";
import i18n from "i18next";


const storedLanguage = localStorage.getItem("lang") || "en";

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: storedLanguage,
  fallbackLng: localStorage.getItem("lang"),
});
export default i18n;

export {
  storedLanguage
}