import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearKissFields, setKiss } from "../../toolkitReducers/actions.slice";
import { UploadPanel } from "./UploadPanel";
import { Preview } from "./Prewiew";
import { KYCControls } from "./KYCControls";
import { useTranslation } from "react-i18next";
import { Popup } from "../../Elements/Popup";
import DepositSuccess from "../Algoritms/DepositSuccess";

export default function KYCPage() {
  const [previewFile, setPreviewFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedDocType, setUploadedDocType] = useState(null);
  const [open, setIsOpen] = useState(false);
  const { kissFields } = useSelector((state) => state.state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Получаем первый выбранный файл

    if (file) {
      setPreviewFile(URL.createObjectURL(file)); // Создаем URL для выбранного файла
      setSelectedFile({ selectedFile: file });
      setUploadedDocType(e.target.name);
    }
  };
  const closeModal = (e) => {
    if (e.target.id === "overlay" || e.code === "Escape") {
      setIsOpen(false);
    }
  };
  const closePopup = () => {
    setIsOpen(false);
    navigate("/profile");
  };
  const handleUpload = () => {
    if (!selectedFile) {
      // Здесь вы можете отправить файл на сервер или выполнить другие действия с ним
    } else {
    }
  };

  const handleDeleteFile = () => {
    setPreviewFile(null);
    setSelectedFile(null);
    setUploadedDocType(null);
  };

  const clickDone = () => {
    const margedKissFields = {
      name: kissFields.name.value,
      second_name: kissFields.surname.value,
      phone: kissFields.phone.value,
      birth_date: kissFields.birthday.value,
      city: kissFields.city.value,
      country: kissFields.country.value,
      telegram: kissFields.telegram.value, //nety
      document_image: selectedFile,
    };

    dispatch(setKiss(margedKissFields));
    setIsOpen(true);
  };

  const clickForward = () => {
    dispatch(clearKissFields());
    navigate("/profile/kyc/step1");
  };

  return (
    <>
      <h2 className="KYS-section__title">{t("Загрузите ваш документ")}</h2>
      <div className="KYS-section__forms-wrapper">
        {!previewFile && (
          <>
            <UploadPanel
              type="passport"
              handleUpload={handleUpload}
              handleFileChange={handleFileChange}
            />
            <UploadPanel
              type="id-card"
              handleUpload={handleUpload}
              handleFileChange={handleFileChange}
            />
          </>
        )}
        {previewFile && (
          <Preview
            previewFile={previewFile}
            handleDeleteFile={handleDeleteFile}
          />
        )}
      </div>

      <KYCControls
        clickForward={clickForward}
        clickDone={clickDone}
        isUploaded={selectedFile}
      />
      <Popup
        props={{
          expanded: open,
          onModalClose: closeModal,
        }}
        children={
          <DepositSuccess
            title={t("Поздравляем!")}
            description={t("Данные KYC успешно загружены")}
            switchDone={closePopup}
          />
        }
      />
    </>
  );
}
