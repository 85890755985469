export const RangeList = () => {
  return (
    <ul className="range-list">
      <li>0%</li>
      <li>25%</li>
      <li>50%</li>
      <li>75%</li>
      <li>100%</li>
    </ul>
  );
};
