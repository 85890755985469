import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

import { ReactComponent as ArrowDownIcon } from "../../assets/icons/ArrowDownIcon.svg";
import { ReactComponent as GlobusIcon } from "../../assets/icons/GlobusIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import { selectLanguage } from "../../toolkitReducers/selectors";
import { setLanguage } from "../../toolkitReducers/languageSlice";
export default function CustomSelect() {
  const [dropdownState, setDropdownState] = useState(false);
  const { t } = useTranslation();
  const language = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const transformLangString = (str) => {
    return str === "en" ? "ENGLISH" : "RUSSIAN";
  };
  const dropdownWindowRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      dropdownWindowRef.current &&
      !dropdownWindowRef.current.contains(event.target)
    ) {
      setDropdownState(false);
    }
  };

  const handleLanguageChange = (language) => {
    localStorage.setItem("lang", language);
    i18n.changeLanguage(language);
    dispatch(setLanguage(language));
    setDropdownState(false);
  };

  const handleLanguageClick = () => {
    setDropdownState(!dropdownState);
  };

  return (
    <div className="custom-select">
      <button
        className="custom-select__btn"
        tabIndex="0"
        aria-expanded="false"
        aria-controls="dropdown-1"
        onClick={handleLanguageClick}
      >
        <span>
          <GlobusIcon />
        </span>
        <span
          data-button-label
          className={`custom-select__btn-text ${dropdownState ? "active" : ""}`}
        >
          {t(transformLangString(language))}
        </span>
        <ArrowDownIcon />
      </button>

      <ul
        data-dropdown
        className={`custom-select__dropdown list-menu ${
          dropdownState ? "active" : ""
        }`}
        id="dropdown-1"
        ref={dropdownWindowRef}
      >
        <li
          className={`custom-select__dropdown-option ${
            language === "russian" ? "selected" : ""
          }`}
          onClick={() => handleLanguageChange("ru")}
        >
          <button className="btn--reset link custom-select__dropdown-button">
            {t("RUSSIAN")}
          </button>
        </li>
        <li
          className={`custom-select__dropdown-option ${
            language === "english" ? "selected" : ""
          }`}
          onClick={() => handleLanguageChange("en")}
        >
          <button className="btn--reset link custom-select__dropdown-button">
            {t("ENGLISH")}
          </button>
        </li>
      </ul>

      <input
        type="hidden"
        className="custom-select__input-hidden"
        value={language}
      />
    </div>
  );
}
