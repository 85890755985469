import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/ArrowDownIcon.svg";
import { getUserData } from "../../toolkitReducers/auth.slice";
import {
  useSetWithdrawalMutation,
  useGetUserDataQuery,
} from "../../toolkitReducers/cryptouch";
export const Withdraw = ({ switchDone }) => {
  const { currencies } = useSelector((state) => state.state);
  const [selectedCoin, setSelectedCoin] = useState(currencies[0]);
  const [percentage, setPercentage] = useState(0);
  const [walletAddress, setWalletAddress] = useState("");
  const [localError, setLocalError] = useState("");
  const [amount, setAmount] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [activeMenu, setActiveMenu] = useState(0);
  const { error } = useSelector(({ state }) => state);
  const [sliderFillPercentage, setSliderFillPercentage] = useState(0);
  const { t } = useTranslation();
  const { data: userData } = useGetUserDataQuery();
  const withdrawInputRef = useRef(null);

  const dispatch = useDispatch();
  const [setWithdrawal] = useSetWithdrawalMutation();

  useEffect(() => {

    if (!userData) {
      return;
    }

    withdrawInputRef.current.max =
      userData[`main_crypto_balance_${selectedCoin.value}`];
    setAmount(
      (userData[`main_crypto_balance_${selectedCoin.value}`] *
        parseFloat(percentage)) /
        100
    );
  }, [selectedCoin, selectedCoin.value, percentage, userData]);

  const handleCoinChange = (index) => {
    setLocalError("");

    setSelectedCoin(currencies[index]);
  };

  const handlePercentageChange = ({ target }) => {
    setLocalError("");

    setPercentage(target.value);
    setSliderFillPercentage(
      ((target.value - target.min) / (target.max - target.min)) * 100
    );
  };

  const handleWalletAddressChange = (event) => {
    setLocalError("");
    setWalletAddress(event.target.value);
  };
  function checkUserFunds(currency, amount) {
    let sufficient = false;
    switch(currency) {
      case "usdt": sufficient = amount >= 10; break;
      case "btc":  sufficient = amount >= 0.00016; break;
      case "eth":  sufficient = amount >= 0.0029; break;
      default: console.log("unsupported currency type");
    }

    return sufficient;

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!walletAddress) {
      setLocalError("fill address withdraw");
    }else if (Number(amount) <= 0) {
      setLocalError("amount should be more than 0");
    } 
    
    else if(!checkUserFunds(selectedCoin.value, amount)) {
      setLocalError("Минимальная сумма для вывода составляет 10 USDT.");
    } 
    
    else {
      setWithdrawal({
        withdrawal_sum: amount,
        currency: selectedCoin.value,
        address: walletAddress,
      });
      switchDone(true);
    }
    setTimeout(() => {
      dispatch(getUserData());
    }, 1000);
  };

  const changeAmountSumm = (e) => {
    setLocalError("");
let percentageLocal;


    // if (isNaN(parseFloat(e.target.value))) {
    //   setAmount("");
    // } else {
    //   setAmount(parseFloat(e.target.value));
    // }
    if (Number(e.target.value) <= Number(withdrawInputRef.current.max)) {
      percentageLocal =
        (Number(e.target.value) / Number(withdrawInputRef.current.max)) * 100;
      setAmount(e.target.value);
      setPercentage(((percentageLocal - 0) / (100 - 0)) * 100);
      setSliderFillPercentage(((percentageLocal - 0) / (100 - 0)) * 100);
    } else {
      percentageLocal = 100;
      setAmount(withdrawInputRef.current.max);
      setPercentage(((percentageLocal - 0) / (100 - 0)) * 100);
      setSliderFillPercentage(((percentageLocal - 0) / (100 - 0)) * 100);
    }
   
  };

  const showCurrentCoin = () => {
    return (
      userData?.[`main_crypto_balance_${selectedCoin.value}`] +
      " " +
      selectedCoin.value.toUpperCase()
    );
  };
  const chooseMenuItem = (index) => {
    setActiveMenu(index);
    setExpanded(false);
    handleCoinChange(index);
  };

  return (
    <div className="withdrawal-page deposit-content-wrapper">
      <h2>{t("Вывод Main Balance")}</h2>
      <p className="deposit-window-label">{t("Выбор монеты")}</p>
      <div
        className={
          expanded
            ? "deposit-dropdown-container expanded"
            : "deposit-dropdown-container"
        }
      >
        <button
          tabIndex="0"
          aria-expanded="false"
          aria-controls="dropdown-2"
          onClick={() => setExpanded(true)}
          className="deposit-dropdown-container-btn"
        >
          <span>
            <ArrowDownIcon />
          </span>
          <span data-button-label>{currencies[activeMenu].name}</span>
        </button>
        {expanded && (
          <ul className="deposit-dropdown-list">
            {currencies.map(({ name, index }) => {
              if (index === 0) {
                return (
                  <li key={index}>
                    <button
                      role="tab"
                      type="button"
                      tabIndex="0"
                      aria-expanded={true}
                      id={`tab-replenish-deposit-${index}`}
                      aria-controls={`tabpanel-replenish-deposit-${index}`}
                      onClick={() => chooseMenuItem(index)}
                      aria-selected="true"
                    >
                      <span>
                        <ArrowDownIcon />
                      </span>
                      <span>{name}</span>
                    </button>
                  </li>
                );
              }
              return (
                <li key={index}>
                  <button
                    data-dropdown-button
                    role="tab"
                    type="button"
                    tabIndex="0"
                    aria-expanded={true}
                    id={`tab-replenish-deposit-${index}`}
                    aria-controls={`tabpanel-replenish-deposit-${index}`}
                    onClick={() => chooseMenuItem(index)}
                    aria-selected="true"
                  >
                    <span>{name}</span>
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className="deposit-window__currency-address-wrapper">
        <input
          className="copy-email"
          ref={withdrawInputRef}
          type="number"
          placeholder={t("введите сумму")}
          value={amount}
          onChange={changeAmountSumm}
        />
      </div>
      <div className="details-section__progress-bar-wrapper rel">
        <div className="details-section__progress-bar">
          <div className="form-container form-container--range range">
            <ul className="range__list">
              <li className="range__list-item">0%</li>
              <li className="range__list-item">25%</li>
              <li className="range__list-item">50%</li>
              <li className="range__list-item">75%</li>
              <li className="range__list-item">100%</li>
            </ul>
            <input
              type="range"
              id="percentEth"
              name="percentage"
              value={percentage}
              onChange={handlePercentageChange}
              min={0}
              max={100}
              step={1}
              style={{
                backgroundSize: `${sliderFillPercentage}% 100%, 100% 100%`,
              }}
            />

            <div className="min-max__range-item-wrapper">
              <p className="min-max__range-item-min">
                {`${amount || 0} ${selectedCoin.value.toUpperCase()}`}
              </p>
              <p className="min-max__range-item-max">{showCurrentCoin()}</p>
            </div>
          </div>
          <ul className="range-points-container withdraw">
            <li
              className={
                sliderFillPercentage >= 0 ? "rangePoint selected" : "rangePoint"
              }
              style={{ left: "20%" }}
            ></li>
            <li
              className={
                sliderFillPercentage >= 25
                  ? "rangePoint selected"
                  : "rangePoint"
              }
              style={{ left: "40%" }}
            ></li>
            <li
              className={
                sliderFillPercentage >= 50
                  ? "rangePoint selected"
                  : "rangePoint"
              }
              style={{ left: "59%" }}
            ></li>
            <li
              className={
                sliderFillPercentage >= 75
                  ? "rangePoint selected"
                  : "rangePoint"
              }
              style={{ left: "79%" }}
            ></li>
            <li
              className={
                sliderFillPercentage >= 100
                  ? "rangePoint selected"
                  : "rangePoint"
              }
              style={{ left: "99%" }}
            ></li>
          </ul>
        </div>
      </div>

      <div>
        <p className="deposit-window-label">
          {selectedCoin.value.toUpperCase() + " " + t("адрес")}
        </p>
        <div className="deposit-window__currency-address-wrapper">
          <label
            className="copy-email-label"
            aria-roledescription="button"
            htmlFor={`${selectedCoin.name}-address`}
          ></label>

          <input
            className="copy-email"
            type="text"
            name={`${selectedCoin.name}-address`}
            id={`${selectedCoin.name}-address`}
            placeholder={t("Введите адрес для отправки")}
            value={walletAddress}
            onChange={handleWalletAddressChange}
          />
        </div>
      </div>
      <div className="deposit-window__info-wrapper">
        <p>
          {t(
            "Внимание, проверяйте адрес Вашего кошелька и тип монеты!"
          )}
        </p>
        <p>
          {t(
            "Минимальная сумма для вывода составляет 10 USDT."
          )}
        </p>
        {error && (
          <p
            style={{ fontSize: "20px", lineHeight: "1.5" }}
            className="withdrawal-section__add-deposit-description"
          >
            {/* *{t("Средства могут сниматься раз в 10 дней")} */}
            error
          </p>
        )}
        {error && (
          <p
            style={{ fontSize: "20px", lineHeight: "1.5" }}
            className="withdrawal-section__add-deposit-description"
          >
            *{t("Средства могут сниматься раз в 10 дней")}
          </p>
        )}
        {localError && (
          <p
            style={{ fontSize: "20px", lineHeight: "1.5" }}
            className="withdrawal-section__add-deposit-description"
          >
            *{t(localError)}
          </p>
        )}
      </div>
      <button className="btn next" onClick={handleSubmit}>
        {t("Далее")}
      </button>
    </div>
  );
};
