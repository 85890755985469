import { forwardRef } from "react";
import Sextagon from "../../assets/icons/features-section/features-sextagon.svg";
import SextagonGray from "../../assets/icons/features-section/features-sextagon-gray.svg";
import SextagonViolet from "../../assets/icons/features-section/features-sextagon-violet.svg";
import DarkSextagon from "../../assets/icons/features-section/dark-sextagon.svg";
import DarkSextagonGray from "../../assets/icons/features-section/dark-sextagon-gray.svg";
import DarkSextagonViolet from "../../assets/icons/features-section/dark-sextagon-violet.svg";
import Cryptouch from "../../assets/icons/features-section/cryptouch-logo.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
const Features = forwardRef((props, ref) => {

  const theme = useSelector(selectTheme)
  const { t } = useTranslation();
  return (
    <section ref={ref} className="main-page-section features-section">
      <div className="section-inner-container">
        <div className="features-section-left-side rel">
          <div className="sextagon-thumb rel">
            <img src={theme === "light" ? Sextagon : DarkSextagon} className="sextagon" alt="sextagon" />
            <div className="text-content-holder">
              <span>{t("Рост")}:</span>
              <p>{t("Совместное развитие")}</p>
            </div>
          </div>
          <div className="sextagon-thumb rel">
            <img src={theme === "light" ? SextagonGray : DarkSextagonGray} className="sextagon" alt="sextagon" />
            <div className="text-content-holder">
              <span>{t("Возможности")}</span>
              <p>{t("Открытие перспектив")}</p>
            </div>
          </div>
          <div className="sextagon-thumb rel">
            <img src={theme === "light" ? SextagonViolet : DarkSextagonViolet} className="sextagon" alt="sextagon" />
            <div className="text-content-holder">
              <span>{t("Заработок")}:</span>
              <p>{t("Выгодное партнерство")}</p>
            </div>
          </div>
          <div className="sextagon-thumb rel">
            <img src={Cryptouch} className="sextagon" alt="cryptouch" />
          </div>
        </div>
        <div className="features-section-right-side">
          <h3>{t("Почему мы предлагаем делиться прибылью с вами?")}</h3>
          <p>
            {t(
               "Потому что сфера криптовалютных бирж и биржевых пар дают огромное количество возможностей, каждый день создаются стартапы и новые крипто проекты, которые предоставляют шанс заработать привлекательные проценты."
            )}
          </p>
          <Link to="/register" className="main-page-button-link btn">{t("Начать прямо сейчас")}</Link>
        </div>
      </div>
    </section>
  );
});
export { Features };
