import { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BTCCoin from "../../assets/icons/hero/btc-coin.png";
import Cube from "../../assets/icons/hero/cube.png";
import ETHCoin from "../../assets/icons/hero/eth-coin.png";
import HorizontalBar from "../../assets/icons/hero/horizontal-bar.svg";
import PercentsAndLines from "../../assets/icons/hero/percents-and-lines.png";
import ProfilePreview from "../../assets/icons/hero/profile-preview.png";
import { CurrencyCard } from "./CurrencyCard";
import { Link } from "react-router-dom";
import { getCryptoPrices} from "../../toolkitReducers/actions.slice";
import { selectCoinPriceBTC, selectCoinPriceETH, selectLanguage } from "../../toolkitReducers/selectors";

import { useDispatch, useSelector } from "react-redux";
const Hero = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const coinPriceETH = useSelector(selectCoinPriceETH);
  const coinPriceBTC = useSelector(selectCoinPriceBTC);
  useEffect(() => {
    dispatch(getCryptoPrices());

  }, [dispatch, coinPriceETH?.price, coinPriceBTC?.price]);
  const lang = useSelector(selectLanguage)

  return (
    <section ref={ref} className="main-page-section hero-section">
      <div className="section-inner-container">
        <h1 className="hero-section-heading">
          {t("Мы разработали алгоритмы которые будут работать на Вас")}
        </h1>
        <h2>{t("Коснитесь будущего вместе с СrypTouch")}</h2>
        <div className="hero-canvas rel">
          <div className="left-side-hero-holder">
            <img src={BTCCoin} className="btc-coin" alt="btc-coin" />
            <img
              src={ProfilePreview}
              className="profile-preview"
              alt="profile-preview"
            />
            <img src={ETHCoin} className="eth-coin" alt="eth-coin" />
          </div>
          <img
            src={HorizontalBar}
            className="horizontal-bar"
            alt="horizontal-bar"
          />
          <img src={Cube} className="cube" alt="cube" />
          <img
            src={PercentsAndLines}
            className="percents-and-lines"
            alt="percents-and-lines"
          />
        </div>
        <div className="main-page-buttons-holder">
          <Link to="/register" className="main-page-button-link btn">
            {t("ЗАРЕГИСТРИРОВАТЬСЯ ПРЯМО СЕЙЧАС")}
          </Link>
          <a className="main-page-button-link btn light" rel="noreferrer" target="_blank" href={lang === 'en' ? "https://www.youtube.com/watch?v=SHS4pLtPbcw" : "https://www.youtube.com/watch?v=_cmWSqBhzFM"}>
            {t("ВИДЕО РЕГИСТРАЦИИ")}
          </a>
        </div>
        <div className="hero-coin-cards">

          <CurrencyCard
            currency="BTC"
            course={coinPriceBTC?.percent_change_24h}
            price={coinPriceBTC?.price}
          />
          <CurrencyCard currency="ETH"
            course={coinPriceETH?.percent_change_24h}
            price={coinPriceETH?.price} />
        </div>
      </div>
    </section>
  );
});

export { Hero };
