import { ReactComponent as ArrowBack } from "../../assets/icons/arrowBack.svg";
import { ReactComponent as ArrowForward } from "../../assets/icons/arrowForward.svg";
import { useTranslation } from "react-i18next";
export const KYCControls = ({ clickForward, clickDone, isUploaded }) => {

  const { t } = useTranslation();


  return (
    <div className="KYS-section__buttons-wrapper">
      <button
        className="KYS-section__prev-page btn"
        onClick={clickForward}
        role="tab"
        type="button"
        tabIndex="0"
        aria-controls="tabpanel-2"
        aria-selected="false"
      >
        <span className="KYS-section__arrow-icon">
          <ArrowBack />
        </span>
        <span>{t("Назад")}</span>
      </button>
      <button
        className="KYS-section__next-page btn"
        onClick={clickDone}
        role="tab"
        type="button"
        tabIndex="0"
        aria-controls="tabpanel-2"
        aria-selected="false"
      >
        <span>{t(isUploaded ? "Готово" : "Далее")}</span>
        {!isUploaded && (
          <span>
            <ArrowForward />
          </span>
        )}
      </button>
    </div>
  );
};
