import { useTranslation } from "react-i18next";
import { transformDateTimeForSessionsTable } from "../../helpers/createDateString";
const TableLarge = ({ refferals }) => {
  const { t } = useTranslation();
  return (
    <div className="referals-page__table-wrapper--large">
      <table>
        <thead>
          <tr className="referals-page__thead rel">
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("№")}</span>
              </div>
            </th>
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Почта партнера")}</span>
              </div>
            </th>
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Date joined")}</span>
              </div>
            </th>

            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Дата деп")}</span>
              </div>
            </th>
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Баланс") + " BTC"}</span>
              </div>
            </th>
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Баланс") + " ETH"}</span>
              </div>
            </th>
            <th>
              <div className="referals-page__table-td-wrapper">
                <span>{t("Баланс") + " USDT"}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {refferals?.all_referrals?.length > 0 &&
            refferals.all_referrals.map((ref, idx) => (
              <tr
                className="referals-page__table-body-row"
                key={`${idx}-lrg-table-row`}
              >
                <td>{idx + 1}</td>
                <td>{ref.email}</td>
                <td>
                  {transformDateTimeForSessionsTable(ref.registration_date)}
                </td>
                <td>
                  {transformDateTimeForSessionsTable(ref.last_deposit_date) ||
                    "-"}
                </td>
                <td>{ref.bonus_crypto_balance.btc}</td>
                <td>{ref.bonus_crypto_balance.eth}</td>
                <td>{ref.bonus_crypto_balance.usdt}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export { TableLarge };