import { ReactComponent as FlowerIcon } from "../assets/icons/FlowerIcon.svg";
import { ReactComponent as AttentionIcon } from "../assets/icons/AttentionIcon.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLanguage } from "../toolkitReducers/selectors";

const Dropdown = () => {
  const language = useSelector(selectLanguage);
  const { t } = useTranslation();
  return (
    <div className="aside-drop-down-container">
      <div className="aside-dropdown-inner-wrapper">
        <span>
          <FlowerIcon />
        </span>
        <span>{t("AI")}</span>
      </div>
      <nav className="main-nav__wrapper">
        <ul className="aside-drop-down-list">
          <li>
            <NavLink className="link" to="balance">
              {t("Main balance")}
            </NavLink>
          </li>
          <li>
            <NavLink className="link" to="algoritms">
              {t("Ai Pulls")}
            </NavLink>
          </li>
          <li>
            <NavLink className="link" to="faq">
              {t("Settings Pulls")}
            </NavLink>
          </li>
          <li>
            <NavLink className="link" to="statistics">
              {t("Statistics")}
            </NavLink>
          </li>
          <li>
            <NavLink className="link" to="referals">
              {t("Referrals")}
            </NavLink>
          </li>
          <li className="main-nav__first-lvl-item">
            <a
              className="link"
              href={
                language === "en"
                  ? "https://cryptouch.ai/staticDirectory/pages/faq.pdf"
                  : "https://cryptouch.ai/staticDirectory/pages/faq-ru.pdf"
              }
            >
              <span>
                <AttentionIcon />
              </span>
              <span>{t("F.A.Q.")}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export { Dropdown };
