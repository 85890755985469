import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "../../Pages/Binary/Dropdown";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
import { TransferSelects } from "../../Pages/Binary/TransferSelects";
import {
  useSetTransferMutation,
  useGetUserDataQuery,
} from "../../toolkitReducers/cryptouch";

const Transfer = ({ switchDone }) => {
  const balances = ["main", "bonus", "profit"];
  const theme = useSelector(selectTheme);
  const { currencies } = useSelector((state) => state.state);
  const [selectedCoin, setSelectedCoin] = useState(currencies[0]);
  const [selectedFrom, setSelectedFrom] = useState(balances[0]);
  const [selectedTo, setSelectedTo] = useState("trading");
  const [availableToBalances, setAvailableToBalances] = useState(["trading"]);
  const [percentage, setPercentage] = useState(0);
  const [amount, setAmount] = useState("0");
  const [localError, setLocalError] = useState("");
  const [sliderFillPercentage, setSliderFillPercentage] = useState(0);
  const { data, isFetching } = useGetUserDataQuery();
  const { t } = useTranslation();
  const [setTransfer, { error }] = useSetTransferMutation();
  const transferInputRef = useRef(null);

  useEffect(() => {
    let availableBalancesTo;

    switch (selectedFrom) {
      case "bonus":
        availableBalancesTo = ["main"];
        break;
      case "profit":
        availableBalancesTo = ["main"];
        break;
      case "main":
        availableBalancesTo = ["trading"];
        break;
      default:
        availableBalancesTo = null;
    }

    setAvailableToBalances(availableBalancesTo);
  }, [selectedFrom]);

  useEffect(() => {
    let getAmount =
      selectedFrom === "profit"
        ? `trading_profit_${selectedCoin.value}`
        : `${selectedFrom}_crypto_balance_${selectedCoin.value}`;
    // line above
    const depositAmount = data && data[getAmount];
    transferInputRef.current.max = data?.[getAmount];
    setAmount(((depositAmount * parseFloat(percentage)) / 100).toString());
  }, [selectedCoin, selectedCoin.value, percentage, selectedFrom, data]);

  const handleCoinChange = (index) => {
    setSelectedCoin(currencies[index]);
  };

  const handleAccountSelect = (balanceName, direction) => {
    setLocalError("");

    if (direction === "from") setSelectedFrom(balanceName);
    if (direction === "to") setSelectedTo(balanceName);
  };

  const handlePercentageChange = ({ target }) => {
    setLocalError("");
    setPercentage(target.value);
    setSliderFillPercentage(
      ((target.value - target.min) / (target.max - target.min)) * 100
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let balance =
      selectedFrom === "profit"
        ? data[`trading_profit_${selectedCoin.value}`]
        : data[`${selectedFrom}_crypto_balance_${selectedCoin.value}`];

    if (selectedFrom === selectedTo) {
      setLocalError("Balances shold not be the same");
      return;
    }
    if (Number(amount) < 0 || Number(amount) === 0) {
      setLocalError("Summ should be more that 0");
      return;
    }
    if (Number(amount) > Number(balance)) {
      setLocalError("Summ should be less than ballance");
      return;
    }

    let from = selectedFrom === "profit" ? "trading" : selectedFrom;
    let to = selectedFrom === "profit" ? "main" : selectedTo;

    setTransfer({
      withdrawal_sum: amount,
      from_ticker: selectedCoin.value,
      to_ticker: selectedCoin.value,
      from: from,
      to: to,
    });

    switchDone(true);
  };

  const showCurrentCoin = () => {
    let getAmount =
      selectedFrom === "profit"
        ? `trading_profit_${selectedCoin.value}`
        : `${selectedFrom}_crypto_balance_${selectedCoin.value}`;

    return data[getAmount] + " " + selectedCoin.value.toUpperCase();
  };

  const changeAmountSumm = (e) => {
    let percentageLocal;
    if (Number(e.target.value) <= Number(transferInputRef.current.max)) {
      percentageLocal =
        (Number(e.target.value) / Number(transferInputRef.current.max)) * 100;
      setAmount((e.target.value));
      setPercentage(((percentageLocal - 0) / (100 - 0)) * 100);
      setSliderFillPercentage(((percentageLocal - 0) / (100 - 0)) * 100);
    } else {
      percentageLocal = 100;
      setAmount((transferInputRef.current.max));
      setPercentage(((percentageLocal - 0) / (100 - 0)) * 100);
      setSliderFillPercentage(((percentageLocal - 0) / (100 - 0)) * 100);
    }
  };

  return (
    <div
      className={
        theme === "light"
          ? "withdrawal-page transfer-page"
          : "withdrawal-page transfer-page dark-profile"
      }
    >
      <div className="page__sections-wrapper medium-wrapper">
        <section className="withdrawal-section">
          <h1 className="withdrawal-section__main-heading h3">
            {t("Transfer")}
          </h1>
          <div className="withdrawal-section__wrapper-side">
            <div className="withdrawal-section__balance withdrawal-section__balance--full">
              <h3 className="withdrawal-section__balance-heading">
                {t(`${selectedFrom} balance`)}
              </h3>
              <p className="withdrawal-section__balance-money">
                {!isFetching && showCurrentCoin()}
              </p>
            </div>

            <div className="withdrawal-section__add-deposit">
              <>
                {error && error?.sum && error?.sum?.[0] && (
                  <p
                    style={{ fontSize: "20px", lineHeight: "1.5" }}
                    className="withdrawal-section__add-deposit-description"
                  >
                    {error.sum === "Invalid balances provided"
                      ? t("Invalid balances provided")
                      : error.sum[0]}
                  </p>
                )}
              </>
            </div>
          </div>
          <TransferSelects
            handleAccountSelect={handleAccountSelect}
            balances={balances}
            selectedFrom={selectedFrom}
            selectedTo={selectedTo}
            availableToBalances={availableToBalances}
          />
          <div className="details-section__progress-bar-wrapper rel">
            <div className="details-section__progress-bar">
              <div className="form-container form-container--range range">
                <ul className="range__list">
                  <li className="range__list-item">0%</li>
                  <li className="range__list-item">25%</li>
                  <li className="range__list-item">50%</li>
                  <li className="range__list-item">75%</li>
                  <li className="range__list-item">100%</li>
                </ul>
                <input
                  type="range"
                  id="percentEth"
                  name="percentage"
                  value={percentage}
                  onChange={handlePercentageChange}
                  min="0"
                  max="100"
                  step="1"
                  style={{
                    backgroundSize: `${sliderFillPercentage}% 100%, 100% 100%`,
                  }}
                />

                <div className="min-max__range-item-wrapper">
                  {/* поставить пересчет в процентах */}
                  <p className="min-max__range-item-min">
                    {amount + " " + selectedCoin.value.toUpperCase()}
                  </p>
                  <p className="min-max__range-item-max">
                    {!isFetching && showCurrentCoin()}
                  </p>
                </div>
              </div>
              <ul className="range-points-container">
                <li
                  className={
                    sliderFillPercentage >= 0
                      ? "rangePoint selected"
                      : "rangePoint"
                  }
                  style={{ left: "20%" }}
                ></li>
                <li
                  className={
                    sliderFillPercentage >= 25
                      ? "rangePoint selected"
                      : "rangePoint"
                  }
                  style={{ left: "40%" }}
                ></li>
                <li
                  className={
                    sliderFillPercentage >= 50
                      ? "rangePoint selected"
                      : "rangePoint"
                  }
                  style={{ left: "59%" }}
                ></li>
                <li
                  className={
                    sliderFillPercentage >= 75
                      ? "rangePoint selected"
                      : "rangePoint"
                  }
                  style={{ left: "79%" }}
                ></li>
                <li
                  className={
                    sliderFillPercentage >= 100
                      ? "rangePoint selected"
                      : "rangePoint"
                  }
                  style={{ left: "99%" }}
                ></li>
              </ul>
            </div>
          </div>
          <div className="withdrawal-section__wrapper form withdrawal-section__form rel">
            <div>
              <div className="tabs-component tabs">
                <div className="tabs__wrapper rel">
                  <div className="withdrawal-section__header">
                    <h2 className="withdrawal-section__body-heading">
                      {t("Выберите монету")}
                    </h2>

                    <div className="tabs-with-dropdown">
                      <div
                        className="tabs__navigation hide-scrollbar"
                        role="tablist"
                        aria-labelledby="tablist"
                      >
                        <Dropdown
                          handleCoinChange={handleCoinChange}
                          selectedFrom={selectedFrom}
                          selectedTo={selectedTo}
                        />
                      </div>
                      <div className="tabs-with-dropdown-input">
                        <input
                          type="number"
                          ref={transferInputRef}
                          placeholder={t("введите сумму")}
                          value={amount}
                          onChange={changeAmountSumm}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tabs__list"
                    id="tabpanel-replenish-deposit-1"
                    role="tabpanel"
                    tabIndex="0"
                    aria-labelledby="tab-replenish-deposit-1"
                  ></div>
                </div>
              </div>

              <button
                className="profile-section__widthdrawal-btn"
                onClick={handleSubmit}
              >
                <span>{t("transfer")}</span>
              </button>

              <p className="withdrawal-section__description">
                {"* " +
                  t(
                    "Платформа не снимает комиссию за вывод средств. Комиссия сети блокчейн будет взята из суммы транзакции."
                  )}
              </p>
            </div>
          </div>
          <>
            {localError && (
              <p
                style={{ fontSize: "20px", lineHeight: "1.5" }}
                className="withdrawal-section__add-deposit-description"
              >
                {t(localError)}
              </p>
            )}
          </>
        </section>
      </div>
    </div>
  );
};

export { Transfer };
