import React from 'react';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../toolkitReducers/selectors';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);



const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data1 = {
//   labels: props.prop.labels,
//   datasets: [
//     {
//       label: "USDT",
//       data: labels.map(() => Math.floor(Math.random() * 2000) - 1000),
//       backgroundColor: "#47b64c",
//       stack: "Stack 0",
//     },
//     {
//       label: "BTC",
//       data: labels.map(() => Math.floor(Math.random() * 2000) - 1000),
//       backgroundColor: "#ff0000",
//       stack: "ETH",
//     },
//     {
//       label: "Dataset 3",
//       data: labels.map(() => Math.floor(Math.random() * 2000) - 1000),
//       backgroundColor: "#ff7508",
//       stack: "Stack 2",
//     },
//   ],
// };


export default function G2(props) {
    const theme = useSelector(selectTheme)
const data1 = {
  labels: props.prop.labels,
  datasets: [
    {
      label: "USDT",
      data: props.prop.data,
      backgroundColor: "#47b64c",
      stack: "Stack 0",
    },
    {
      label: "BTC",
      data: props.prop.data,
      backgroundColor: "#ff0000",
      stack: "ETH",
    },
    {
      label: "ETH",
      data: props.prop.data,
      backgroundColor: "#ff7508",
      stack: "Stack 2",
    },
  ],
};
    const options = {
      responsive: true,
      stacked: false,
      plugins: {
        legend: {
          labels: {
            color: theme === "light" ? "black" : "white",
          },
        },
      },
      scales: {
        y: {
          grid: {
            color: theme === "light" ? "lightgray" : "rgba(255, 255, 255, 0.5)",
          },
          ticks: {
            color: theme === "light" ? "black" : "white",
            beginAtZero: true,
          },
        },
        x: {
          grid: {
            display: false
          },
          ticks: {
            color: theme === "light" ? "black" : "white",
            beginAtZero: true,
          },
        },
      },
    };
    const data = {
      labels: props.prop.labels,
      // labels: ['Su', 'Mo', 'Th', 'We', 'Tu', 'Fr', 'Sa'],
      datasets: [
        {
          label: "Platform profit",
          data: props.prop.data,
          // data: [10, 20, -30, 40, 50, 60, 70],
          borderColor: theme === "light" ? "#337fd0" : "#7D67FF",

          backgroundColor: theme === "light" ? "#337fd0" : "#7D67FF",
          borderWidth: 1,
          borderRadius: 10,
          borderSkipped: false,
        },
      ],
      annotations: [-40, -20, 0, 20, 40, 60, 80],
    };

    return (

        <Bar
            // config={config}
            options={options}
            data={data}
            {...props}
        />
    )
}