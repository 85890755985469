import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Cryptouch } from "../../assets/icons/feedback-section/cryptouch.svg";
import { ReactComponent as Star } from "../../assets/icons/feedback-section/star.svg";
import { FeedbackCard } from "./FeedbackCard";
const feedbacks = [
  {
    text: "Visual editor lets me drag and drop visual blocks to create my crypto trading strategy, fastest api execution times, support for both FTX and Bybit. It is awesome. I can paper trade my bots before setting them live without risking any capital. great platform for advanced bots, NOT for users who want point and click bots.",
    rating: 4,
  },
  {
    text: "Great for advanced crypto trading bots. 30+ exchanges supported. Can use templates, signals, or develop custom bots. Batch backtesting, paper trading, charting, dashboards. No black box code. Trusted team.",
    rating: 4,
  },
  {
    text: "Most comprehensive software I have ever used for crypto trading and support is great.",
    rating: 4,
  },
  {
    text: "The greatest customer experience I have ever received.",
    rating: 5,
  },
  {
    text: "The greatest customer experience I have ever received.",
    rating: 5,
  },
  {
    text: "Visual editor lets me drag and drop visual blocks to create my crypto trading strategy, fastest api execution times, support for both FTX and Bybit. It is awesome. I can paper trade my bots before setting them live without risking any capital. great platform for advanced bots, NOT for users who want point and click bots.",
    rating: 5,
  },
  {
    text: "Great for advanced crypto trading bots. 30+ exchanges supported. Can use templates, signals, or develop custom bots. Batch backtesting, paper trading, charting, dashboards. No black box code. Trusted team.",
    rating: 5,
  },
  {
    text: "Most comprehensive software I have ever used for crypto trading and support is great.",
    rating: 4,
  },
];

const Feedback = forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <section ref={ref} className="main-page-section feedback-section">
      <div className="section-inner-container">
        <ul className="feedback-row">
          {feedbacks.slice(0, 4).map(({ text, rating }, index) => (
            <li key={`row-1-${index}`}>
              <FeedbackCard text={text} rating={rating} />
            </li>
          ))}
        </ul>
        <ul className="feedback-row">
          {feedbacks.slice(3, 7).map(({ text, rating }, index) => (
            <li key={`row-2-${index}`}>
              <FeedbackCard text={text} rating={rating} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
});
export { Feedback };
