import { ReactComponent as X } from "../../assets/icons/footer-main-page/twitter.svg";
import { ReactComponent as Youtube } from "../../assets/icons/footer-main-page/youtube.svg";
import { ReactComponent as Telegram } from "../../assets/icons/footer-main-page/telegram.svg";
import { ReactComponent as Facebook } from "../../assets/icons/footer-main-page/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/icons/footer-main-page/insta.svg";

const links = [
  { name: "Про проект", elem: "promo" },
  { name: "Приоритеты работы", elem: "advantages" },
  { name: "Отзывы", elem: "feedback" },
  { name: "Карта развития", elem: "development" },
  { name: "Партнерская Программа", elem: "partnership" },
];

const socialLinks = [
  {
    name: "x",
    element: <X />,
    link: "https://twitter.com/cryptouch_ai",
  },
  {
    name: "youtube",
    element: <Youtube />,
    link: "https://www.youtube.com/@CrypTouchAI",
  },
  {
    name: "telegram",
    element: <Telegram />,
    link: "https://t.me/ct_support_office",
  },
  {
    name: "facebook",
    element: <Facebook />,
    link: "https://www.facebook.com/profile.php?id=61553277059433",
  },
  {
    name: "instagram",
    element: <Instagram />,
    link: "https://www.instagram.com/cryptouch_ai/",
  },
];


export { links, socialLinks };
