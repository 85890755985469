import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../helpers";
// import BrowserDetector from "browser-dtector";
// const browser = new BrowserDetector(window.navigator.userAgent);
// let showUserAgent = browser.parseUserAgent();

export const cryptouchAPI = createApi({
  reducerPath: "cryptouchApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://cryptouch.ai/dashboard",
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Token  ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserSessions: builder.query({
      query: () => "get_user_sessions/",
      providesTags: ["userSessions"],
    }),
    getTransactionsHistory: builder.query({
      query: () => "transaction_history/",
      providesTags: ["transactionsHistory"],
    }),
    getRisks: builder.query({
      query: () => ({
        url: "get_user_risks/",
      }),
      providesTags: ["risks"],
    }),
    getUserData: builder.query({
      query: () => ({
        url: "get_user_data/",
      }),
      providesTags: ["userData"],
      transformResponse: (response) => response.user,
    }),
    getGlobalStatistics: builder.query({
      query: () => ({
        url: "get_global_statistics/",
      }),
      providesTags: ["globalStatistics"],
    }),
    getGlobalProfit: builder.query({
      query: () => ({
        url: "get_global_profit/",
      }),
    }),
    getRefferals: builder.query({
      query: () => ({
        url: "get_user_referrals/",
      }),
      providesTags: ["referrals"],
    }),
    getUserWallet: builder.query({
      query: (param) => ({
        url: `get_wallet/?type=${param.toUpperCase()}`,
      }),
      providesTags: ["userWallet"],
    }),
    depositSum: builder.mutation({
      query: (param) => ({
        url: "deposit_sum/",
        method: "POST",
        body: {
          sum: param.sum,
          type: param.typeSum,
          address: param.address,
        },
      }),
      invalidatesTags: ["transactionsHistory"],
    }),
    setWithdrawal: builder.mutation({
      query: (params) => ({
        url: "withdrawal_sum/",
        method: "POST",
        body: {
          withdrawal_sum: params.withdrawal_sum,
          currency: params.currency,
          address: params.address,
        },
      }),
      invalidatesTags: ["transactionsHistory", "userData"],
    }),
    setTransfer: builder.mutation({
      query: (params) => ({
        url: "balance_transaction/",
        method: "POST",
        body: {
          amount: params.withdrawal_sum,
          from_ticker: params.from_ticker,
          to_ticker: params.to_ticker,
          from_balance: params.from,
          to_balance: params.to,
        },
      }),
      invalidatesTags: ["transactionsHistory", "userData"],
    }),
    setRisks: builder.mutation({
      query: (param) => ({
        url: "set_risk/",
        method: "POST",
        body: param,
      }),
      invalidatesTags: ["risks"],
    }),
    setUserData: builder.mutation({
      query: ({ ip, os, browser }) => ({
        url: "get_user_data/",
        method: "POST",
        body: {
          ip,
          os,
          browser,
        },
      }),
      invalidatesTags: ["userData"],
    }),
    startAlgorythm: builder.mutation({
      query: ({ tariff, amount, currency }) => ({
        url: "start_algorythm/",
        method: "POST",
        body: {
          tariff,
          amount,
          currency,
        },
      }),
      invalidatesTags: ["transactionsHistory", "userData"],
    }),
  }),
});

export const {
  useGetUserSessionsQuery,
  useSetUserDataMutation,
  useGetTransactionsHistoryQuery,
  useDepositSumMutation,
  useSetWithdrawalMutation,
  useSetTransferMutation,
  useGetRisksQuery,
  useSetRisksMutation,
  useGetUserDataQuery,
  useGetGlobalStatisticsQuery,
  useGetGlobalProfitQuery,
  useGetRefferalsQuery,
  useGetUserWalletQuery,
  useStartAlgorythmMutation,
} = cryptouchAPI;
