import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



const labels = ['Январь', 'Февраль', '', 'April', 'May', 'June', 'July'];

// const labels = ["01-02", "01-03", "01-04", "01-05", "01-06", "01-07", "01-08"]


export default function G1(props) {
    const theme = useSelector(selectTheme);
    const data = {
      labels: props.prop.labels,
      datasets: [
        {
          label: "USDT",
          data: props.prop.data.map((e) => e[0]),
          // data: [2, 5, 3, 8, 14, 10, 6],
          fill: false,
          borderColor: "green",
          borderWidth: 2,
          tension: 0,
          pointStyle: "triangle",
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "green",
          pointBorderWidth: 1,
          pointRadius: 7,
          pointHoverRadius: 10,
        },
        {
          label: "BTC",
          data: props.prop.data.map((e) => e[1]),
          // data: [2, 5, 3, 8, 14, 10, 6],
          fill: false,
          borderColor: "red",
          borderWidth: 2,
          tension: 0,
          pointStyle: "triangle",
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "red",
          pointBorderWidth: 1,
          pointRadius: 7,
          pointHoverRadius: 10,
        },
        {
          label: "ETH",
          data: props.prop.data.map((e) => e[2]),
          // data: [2, 5, 3, 8, 14, 10, 6],
          fill: false,
          borderColor: "blue",
          borderWidth: 2,
          tension: 0,
          pointStyle: "triangle",
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "blue",
          pointBorderWidth: 1,
          pointRadius: 7,
          pointHoverRadius: 10,
        },
      ],
    };

    const config = {
      type: "line",
      data: data,
      options: {
        scales: {
          y: {
            display: true,
            grid: {
              color: theme === "light" ? "lightgray" : "rgba(255, 255, 255, 0.5)",
            },
            ticks: {
              color: theme === "light" ? "black" : "white",
              callback: function (value, index, values) {
                if (index === 0 || index === values.length - 1) {
                  return Math.round((value + Number.EPSILON) * 100) / 100 + "%";
                } else {
                  return "";
                }
              },
            },
          },
          x: {
            grid: {
              color: theme === "light" ? "lightgray" : "rgba(255, 255, 255, 0.5)",
            },
            ticks: {
              color: theme === "light" ? "black" : "white",
              beginAtZero: true,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          annotation: {
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y",
                value: 0,
                borderColor: "#FFF831",
                borderRadius: "50%",
                borderWidth: 1,
              },
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y",
                value: 20,
                borderColor: "#FFF831",
                borderWidth: 1,
                borderRadius: "50%",
              },
            ],
          },
        },
      },
    };


    return (

        <Line
            options={config}
            {...config}
            data={data}
            {...props}
        />
    )
}