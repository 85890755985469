import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
import Icon1 from "../../assets/icons/advantages-section/1.svg";
import Icon2 from "../../assets/icons/advantages-section/2.svg";
import Icon3 from "../../assets/icons/advantages-section/3.svg";
import Icon4 from "../../assets/icons/advantages-section/4.svg";
import Icon5 from "../../assets/icons/advantages-section/5.svg";
import Icon6 from "../../assets/icons/advantages-section/6.svg";
import Icon7 from "../../assets/icons/advantages-section/7.svg";
import Icon8 from "../../assets/icons/advantages-section/8.svg";
import DarkIcon2 from "../../assets/icons/advantages-section/2-dark.svg";
import DarkIcon4 from "../../assets/icons/advantages-section/4-dark.svg";
import DarkIcon5 from "../../assets/icons/advantages-section/5-dark.svg";
import DarkIcon7 from "../../assets/icons/advantages-section/7-dark.svg";
import { Advantage } from "./Advantage";
const Advantages = forwardRef(({ refPartnership }, ref) => {


  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

const advantages = [
    {
        img: Icon1,
    text: "Наши алгоритмы торгуют ежедневно, без выходных",
        description: "Торговый алгоритм— программа, которая круглосуточно следит за рынком и автоматически совершает сделки от имени пользователя или просто рекомендует ему это сделать. Она мониторит рынок и сама принимает решение, когда в него входить и выходить, исходя из того, как она настроена.Настройки доступны каждому пользователю в личном кабинете."
  },
      {
        img:theme === "light" ? Icon2 : DarkIcon2,
        text: "Наши трейдеры программируют робота по своим разработанным проверенным стратегиям",
        description: "Алгоритмы научены нашими трейдерами стратегиями из топ 10. Торговая стратегия - Свинг Трейдинг (Свинг-трейдинг — популярная торговая стратегия, предполагающая получение быстрой прибыли в результате краткосрочного движения цен.)Торговая стратегия - Скальпинг (Скальпинг — это метод торговли, который предполагает быструю покупку и продажу активов, часто в течение нескольких минут или даже секунд.)Торговая стратегия - Дневная Торговля (Стратегия дневной торговли получила широкое распространение в криптовалютном сообществе благодаря своей надуманной волатильности.)Торговая стратегия - Арбитраж (Арбитраж представляет собой процесс покупки и продажи криптовалюты и попытку воспользоваться преимуществом расхождения цен между ними."
  },
          {
        img: Icon3,
        text:  "Мы предоставляем выбор пулов с разными возможностями получения дохода",
        description: "На платформе CrypTouch каждому пользователю подключается индивидуальный алгоритм с возможностью выбора характеристики и настройки. Вы можете выбрать торговый или арбитражный алгоритм, разница между ними это стартовая сумма пополнения и доходность."
  },
              {
        img: theme === "light" ? Icon4 : DarkIcon4,
        text:  "Получайте прибыль от торговли на нескольких рынках одновременно.",
        description: "На данном этапе проводятся тесты работы алгоритмов рынке Форекс и ценных бумаг. Сейчас алгоритмы доступны и активны для торговли на криптовалютном рынке."
  },
                  {
        img: Icon8,
        text:  "Партнерская Программа Получайте больше 5% дополнительно и забирайте бонусы",
        description: "Для каждого пользователя платформы cryptouch.ai доступна партнерская программа. За каждого приглашенного друга, партнера Вам будет начислено от 5% комиссии которая компания выделяет за проделанную работу. Регистрируйтесь, копируйте свою уникальную ссылку и делитесь с друзьями."
  },
                      {
        img: theme === "light" ? Icon5 : DarkIcon5,
        text: "Наши специалисты 24 на 7 следят за поведением рынка и робота",
        description: "Круглосуточный контроль отделом IT за поведением программного обеспечения позволяет полностью контролировать работу алгоритмов. Это минимизирует риски сбоя программы. Также круглосуточный контроль ситуации на рынке от отдела Трейдинг. Данная работа повышает эффективность работы алгоритмов так как трейдеры могут дать дополнительные указания к своим стратегиям."
  },
                          {
        img: Icon6,
        text: "Получайте доход от 2 до 12% в месяц.",
        description: "Базовые настройки торгового алгоритма дают от 2% до 7% профита в месяц. Базовые настройки арбитражного алгоритма дают от 4% до 12% в месяц. В личном кабинете доступны настройки рисков, где каждый пользователь может настроить свой алгоритм и получать от 20% профита в месяц."
  },
                              {
        img: theme === "light" ? Icon7 : DarkIcon7,
        text: "Созданный страховой фонд, который покрывает большинство рисков",
        description: "Страховой фонд — это система безопасности, защищающая трейдеров и алгоритмов от нежелательных потерь и гарантирующая полные выплаты инвесторам с успешными сделками. Основная цель страхового фонда — минимизировать случаи ликвидации позиций."
  },
]

  return (
    <section ref={ref} className="main-page-section advantages-section">
      <div className="section-inner-container">
        <h3>{t("Приоритеты работы с CrypTouch")}</h3>
        <div>
          <div className="thumb-row">
            {
              [...advantages].splice(0, 4).map(({img, text, description}, idx) => <Advantage img={img} text={text} key={idx+'advantage-row-1'} id={idx+'advantage-row-1'} description={description}/>)
            }
          </div>
          <div className="thumb-row">
{
              [...advantages].splice(4, 8).map(({img, text, description}, idx) => <Advantage img={img} text={text} key={idx+'advantage-row-2'} id={idx+'advantage-row-2'} description={description}/>)
            }
          </div>
        </div>
      </div>
    </section>
  );
});

export { Advantages };
