import G4 from "./G4";
import G2 from "./G2";
import G1 from "./G1";
import G7 from "../FooterPages/G7";
import { useTranslation } from "react-i18next";
import {
  useGetUserDataQuery,
  useGetGlobalStatisticsQuery,
  useGetGlobalProfitQuery,
} from "../../toolkitReducers/cryptouch";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
export default function StatisticPage(params) {
  const { t } = useTranslation();
  const { data: userData } = useGetUserDataQuery();
  const { data: statistics } = useGetGlobalStatisticsQuery();

    const { data: dailyProfit } = useGetGlobalProfitQuery();
  const theme = useSelector(selectTheme);
  return (
    <div className="statistics-page page">
      <div className="page__sections-wrapper medium-wrapper">
        <section
          className={
            theme === "light"
              ? "statistics-section "
              : "statistics-section dark-profile"
          }
        >
          <h1 className="statistics-section__main-heading h3">
            {t("Статистика")}
          </h1>

          <div className="statistics-section__balance-wrapper">
            <div className="statistics-section__balance-revenue-per-day">
              <h2 className="statistics-section__balance-heading">
                {t("Текущий баланс")}:
              </h2>
              <p className="statistics-section__balance-counter">
                USDT =
                <span className="statistics-section__balance-counter--usdt">
                  {userData && userData.trading_crypto_balance_usdt}
                </span>
              </p>
              <p className="statistics-section__balance-counter">
                BTC =
                <span className="statistics-section__balance-counter--usdt">
                  {userData && userData.trading_crypto_balance_btc}
                </span>
              </p>
              <p className="statistics-section__balance-counter">
                ETH =
                <span className="statistics-section__balance-counter--usdt">
                  {userData && userData.trading_crypto_balance_eth}
                </span>
              </p>
            </div>
            <div className="statistics-section__balance-revenue-per-day">
              <h2 className="statistics-section__balance-heading">
                {t("Доход за 24 часа")}
              </h2>

              <p className="statistics-section__balance-counter">
                <span className="statistics-section__balance-counter--usdt">
                  {statistics &&
                    `${statistics.user_profit_last_24_hours_usdt} USDT`}
                </span>
              </p>
              <p className="statistics-section__balance-counter">
                <span className="statistics-section__balance-counter--usdt">
                  {statistics &&
                    `${statistics.user_profit_last_24_hours_btc} BTC`}
                </span>
              </p>
              <p className="statistics-section__balance-counter">
                <span className="statistics-section__balance-counter--usdt">
                  {statistics &&
                    `${statistics.user_profit_last_24_hours_eth} ETH`}
                </span>
              </p>
            </div>

            <div className="statistics-section__balance-last-interval">
              <h2 className="statistics-section__balance-heading">
                {t("Получено средств")}
              </h2>

              <p className="statistics-section__balance-counter">
                <span className="statistics-section__balance-counter--usdt">
                  {statistics && `% ${statistics.user_total_profit_usdt} USDT`}
                </span>
              </p>
              <p className="statistics-section__balance-counter">
                <span className="statistics-section__balance-counter--usdt">
                  {statistics && `% ${statistics.user_total_profit_btc} BTC`}
                </span>
              </p>
              <p className="statistics-section__balance-counter">
                <span className="statistics-section__balance-counter--usdt">
                  {statistics && `% ${statistics.user_total_profit_eth} ETH`}
                </span>
              </p>
            </div>
          </div>

          <div className="statistics-section__graphics-wrapper">
            <div className="statistics-section__graphics-column">
              <div className="statistics-section__graphics-row">
                <h2 className="statistics-section__graphics-row-heading-wrapper">
                  <span className="statistics-section__graphics-row-heading">
                    {/* {statistics && statistics.chart_data?.datasets[0].label} */}
                    {t("Общая прибыль платформы за сутки") + " "}
                    {dailyProfit &&
                      dailyProfit?.daily_profit_data &&
                      dailyProfit?.daily_profit_data.length &&
                      Number(
                        dailyProfit?.daily_profit_data[
                          dailyProfit?.daily_profit_data.length - 1
                        ]?.daily_profit
                      ).toFixed(5) + " $"}
                  </span>
                </h2>

                <div className="statistics-section__graphics-row-body">
                  {/* I First */}
                  <G7 />
                </div>
              </div>

              <div className="statistics-section__graphics-row">
                <h2 className="statistics-section__graphics-row-heading-wrapper">
                  <span className="statistics-section__graphics-row-heading">
                    {t("Доходность")}
                  </span>
                  <span className="statistics-section__graphics-row-counter">
                    {statistics && statistics.user_profit_last_week}$
                  </span>
                </h2>

                <div className="statistics-section__graphics-row-body">
                  {/* II Second */}
                  {statistics && statistics.chart_data?.weekly_profit && (
                    <G2
                      prop={statistics && statistics?.chart_data?.weekly_profit}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="statistics-section__graphics-column">
              <div className="statistics-section__graphics-row">
                <h2 className="statistics-section__graphics-row-heading-wrapper">
                  <span className="statistics-section__graphics-row-heading">
                    {" "}
                    {t("Размещено в")} AI Arbitrage
                  </span>
                </h2>

                <div className="statistics-section__graphics-row-body">
                  <div className="statistics-section__graphics-row-body-column">
                    {/* IV Second */}
                    {statistics &&
                      statistics.chart_data?.currencies_balance && (
                        <G1
                          prop={
                            statistics &&
                            statistics.chart_data?.currencies_balance
                          }
                        />
                      )}
                  </div>

                  <div className="statistics-section__graphics-row-body-column">
                    <ul className="statistics-section__graphics-row-vaults-list list-menu">
                      <li className="statistics-section__graphics-row-vaults-item">
                        <span className="statistics-section__graphics-row-vaults-item-currency">
                          BTC
                        </span>
                        <span className="statistics-section__graphics-row-vaults-item-price">
                          (
                          {statistics &&
                            statistics.chart_data?.currencies_balance.btc}
                          )
                        </span>
                      </li>
                      <li className="statistics-section__graphics-row-vaults-item">
                        <span className="statistics-section__graphics-row-vaults-item-currency">
                          ETH
                        </span>
                        <span className="statistics-section__graphics-row-vaults-item-price">
                          (
                          {statistics &&
                            statistics.chart_data?.currencies_balance.eth}
                          )
                        </span>
                      </li>
                      <li className="statistics-section__graphics-row-vaults-item">
                        <span className="statistics-section__graphics-row-vaults-item-currency">
                          USDT
                        </span>
                        <span className="statistics-section__graphics-row-vaults-item-price">
                          (
                          {statistics &&
                            statistics.chart_data?.currencies_balance.usdt}
                          )
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="statistics-section__graphics-row">
                <h2 className="statistics-section__graphics-row-heading-wrapper">
                  <span className="statistics-section__graphics-row-heading">
                    {" "}
                    {t("Процент доходности за период")}
                  </span>
                </h2>

                <div className="statistics-section__graphics-row-body">
                  {/* V Second */}
                  {statistics &&
                    statistics.chart_data?.percent_weekly_profit && (
                      <G4
                        prop={
                          statistics &&
                          statistics.chart_data?.percent_weekly_profit
                        }
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
