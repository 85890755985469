import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
import { ReactComponent as SuccessDarkMode } from '../../assets/icons/Popups/success-dark-mode.svg';
import { ReactComponent as WarningDarkMode } from "../../assets/icons/Popups/warning-dark-mode.svg";
import { ReactComponent as Success } from "../../assets/icons/Popups/success.svg";
import { ReactComponent as Warning } from "../../assets/icons/Popups/warning.svg";
import { ReactComponent as Failure } from "../../assets/icons/Popups/failure.svg";
export default function DepositSuccess({
  title = "Поздравляем!",
  description = "Ваша заявка в обработке",
  switchDone,
  type = "success",
  btnText="OK"
}) {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  return (
    <div className="modal-dialog__success-wrapper">
      <div className="modal-dialog__success-icon">
        {type === "success" && theme === "light" && <Success />}
        {type === "success" && theme !== "light" && <SuccessDarkMode />}
        {type === "warning" && theme === "light" && <Warning />}
        {type === "warning" && theme !== "light" && <WarningDarkMode />}
        {type === "failure" && <Failure />}
      </div>

      <h2 className="modal-dialog__success-heading bold">{t(title)}</h2>

      <p className="modal-dialog__success-description bold">{t(description)}</p>

      <button
        onClick={switchDone}
        className="btn"
        type="button"
        data-close-popup
      >
        {t(btnText)}
      </button>
    </div>
  );
}
