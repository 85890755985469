import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as TelegramIcon } from "../assets/icons/TelegramIcon.svg";
import { ReactComponent as YouTubeIcon } from "../assets/icons/YouTubeIcon.svg";
import { ReactComponent as Facebook } from "../assets/icons/Facebook.svg";
import { ReactComponent as InstaIcon } from "../assets/icons/InstaIcon.svg";
import { ReactComponent as LogoIcon } from "./../assets/images/logo.svg";

export default function Footer(params) {
  const location = useLocation();
  const { t } = useTranslation();
  const isLogin = location.pathname === "/login" ? true : false;
  const disableLink = (e) => {
    e.preventDefault();
    return;
  };
  return (
    <footer className={`footer ${isLogin && "public-footer"}`}>
      <div className="footer__main">
        <div className="footer__main-info">
          <div className="footer__main-info-logo rel">
            {/* <a className="logo-link img-abs" href="/index.html"></a> */}
            {/* <img className="img-abs" src="./../assets/images/logo.svg" alt="logotype"/> */}
            <Link to="/profile">
              <LogoIcon className="img-abs" />
              {/* <img src={logoPNg} alt="" /> */}
            </Link>
          </div>
          <div className="footer__main-about-wrapper">
            {/* <h3 className="footer__main-about-title">{t("About Us")}</h3> */}
            <ul className="footer__main-about-list">
              <li className="footer_main-about-item">
                <a
                  href=""
                  onClick={disableLink}
                  className="footer_main-about-link"
                >
                  {t("Company's history")}
                </a>
              </li>
              <li className="footer_main-about-item">
                <a
                  className="footer_main-about-link"
                  href="https://t.me/cryptouchai"
                  onClick={disableLink}
                >
                  {t("News")}
                </a>
              </li>
              <li className="footer_main-about-item">
                <a
                  href=""
                  onClick={disableLink}
                  className="footer_main-about-link"
                >
                  {t("Marketing analytics")}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__main-right-wrapper">
          <div className="footer__main-social-links">
            <a
              className="link"
              target="_blank"
              href="https://www.youtube.com/@CrypTouchAI"
              rel="noreferrer"
            >
              <YouTubeIcon />
            </a>
            <a
              className="link"
              target="_blank"
              href="https://www.instagram.com/cryptouch_ai/"
              rel="noreferrer"
            >
              <InstaIcon />
            </a>
            <a
              className="link"
              target="_blank"
              href="https://www.facebook.com/profile.php?id=61553277059433"
              rel="noreferrer"
            >
              <Facebook />
            </a>
            <a
              className="link"
              target="_blank"
              href="https://t.me/cryptouch_ai"
              rel="noreferrer"
            >
              <TelegramIcon />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
