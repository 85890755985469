export const ProgressBar = ({
  percentage,
  handlePercentageChange,
  sliderFillPercentage,
}) => {
  function setTranslationValue(idx) {
    let translateLeft = idx <= 3 ? 25 : 24.5;
    return { left: idx * translateLeft + "%" };
  }

  return (
    <div className="range-input-thumb">
      <input
        type="range"
        name="percentage"
        value={percentage}
        onChange={handlePercentageChange}
        min="0"
        max="100"
        step="1"
        style={{
          backgroundSize: `${sliderFillPercentage}% 100%, 100% 100%`,
        }}
      />
      <ul className="range-points-container">
        {Array(5)
          .fill("")
          .map((__, idx) => (
            <li
              key={idx + "range-point"}
              className={
                sliderFillPercentage >= idx * 25
                  ? "rangePoint selected"
                  : "rangePoint"
              }
              style={setTranslationValue(idx)}
            ></li>
          ))}
      </ul>
    </div>
  );
};
