import { useState } from "react";

function DropdownTransferSelects({ handleAccountSelect, balances, direction }) {
  const [isOpenMenu, setOpenMenu] = useState(false);

  const [activeMenu, setActiveMenu] = useState(balances[0]);
  const chooseMenuItem = (e) => {
    setActiveMenu(e.target.textContent);

    setOpenMenu(false);
    handleAccountSelect(e.target.textContent, direction);
  };

  return (
    <div className="custom-select dropdown-transfer">
      <button
        className="custom-select__btn"
        tabIndex="0"
        aria-expanded="true"
        onClick={() => setOpenMenu(true)}
        aria-controls="dropdown-2"
      >
        <svg
          className="icon"
          width="14"
          height="9"
          display={isOpenMenu ? "none" : "block"}
          viewBox="0 0 14 9"
          fill="none"
        >
          <path
            d="M0.721313 1.42859L6.86885 7.50002L13.0164 1.42859"
            stroke="#FFF831"
            color="currentColor"
            strokeWidth="1.5"
          />
        </svg>
        <span data-button-label className="custom-select__btn-text">
          {direction === "from" ? activeMenu : balances[0]}
          {/* // activeMenu may be replaced with a string containing balanceName */}
        </span>
      </button>

      <ul
        data-dropdown
        className={`custom-select__dropdown ${isOpenMenu ? "active" : ""}`}
        list-menu="true"
        id="dropdown-2"
      >
        {balances &&
          balances.map((item, index) => {
            return (
              <li className="custom-select__dropdown-option" key={index}>
                <button
                  className="tabs__header-tab btn--reset link custom-select__dropdown-button"
                  data-dropdown-button
                  role="tab"
                  type="button"
                  tabIndex="0"
                  aria-expanded={true}
                  id={`tab-replenish-deposit-${index}${direction}`}
                  aria-controls={`tabpanel-replenish-deposit-${index}${direction}`}
                  onClick={(e) => chooseMenuItem(e)}
                  aria-selected="true"
                >
                  {item}
                </button>
              </li>
            );
          })}
      </ul>

      <input
        type="hidden"
        className="custom-select__input-hidden"
        value="Ethereum"
      />
    </div>
  );
}

export { DropdownTransferSelects };
