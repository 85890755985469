import { useSelector } from "react-redux";
import { selectLanguage } from "../../toolkitReducers/selectors";
import { useTranslation } from "react-i18next";
const FooterLinks = () => {
  const language = useSelector(selectLanguage);
  const { t } = useTranslation();
  let english = language === "en";
  const footerLinks = [
    {
      name: "FAQ",
      link: english
        ? "https://cryptouch.ai/staticDirectory/pages/faq.pdf"
        : "https://cryptouch.ai/staticDirectory/pages/faq-ru.pdf",
    },
    { name: "Новости", link: "https://t.me/cryptouchai" },
    {
      name: "Презентация",
      link: english
        ? "https://cryptouch.ai/staticDirectory/pages/presentation.pdf"
        : "https://cryptouch.ai/staticDirectory/pages/presentation-ru.pdf",
    },
    {
      name: "Документация",
      link: english
        ? "https://cryptouch.ai/staticDirectory/pages/public.pdf"
        : "https://cryptouch.ai/staticDirectory/pages/public-ru.pdf",
    },
    {
      name: "Регистрация",
      link: "https://cryptouch.ai/staticDirectory/pages/register.pdf",
    },
  ];
  return (
    <ul className="main-header-links-list">
      {footerLinks.map(({ name, link }, idx) => (
        <li key={`${idx}-footer-link`}>
          <a href={link}>{t(name)}</a>
        </li>
      ))}
    </ul>
  );
};

export {
    FooterLinks
}