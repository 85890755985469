import { currencies } from "../../helpers/lists";
import { ReactComponent as Expand } from "../../assets/icons/transaction-filter/expand-filter-triangle.svg";

export const TransactionsFilter = ({
  handleSelectCurrecy,
  expanded,
  toggleDropdown,
}) => {
  return (
    <div className="transaction-filter-wrapper">
      <button onClick={toggleDropdown} className="expand-filter-button">
        <Expand className={expanded ? "expanded" : ""} />
      </button>
      {expanded && (
        <ul className="transactions-filter-dropdown">
          {currencies.map((c, idx) => (
            <li key={idx + "transactions-filter-item"}>
              <button onClick={() => handleSelectCurrecy(c)}>
                {c.toUpperCase()}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
