import { Algoritms } from "./Algoritms";
import { Advantages } from "./Advantages";
import { Development } from "./Development";
import { Features } from "./Features";
import { Feedback } from "./Feedback";
import { Footer } from "./Footer";
import { Hero } from "./Hero";
import { Header } from "./Header";
import { Partnership } from "./Partnership";
import { Promo } from "./Promo";
import { VideoBlock } from "./VideoBlock";
import { Events } from "./Events";

export {
  Algoritms,
  Advantages,
  Development,
  Features,
  Feedback,
  Footer,
  Hero,
  Header,
  Partnership,
  Promo,
  VideoBlock,
  Events,
};
